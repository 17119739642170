import { Component, ElementRef, HostBinding, HostListener, Injectable, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import * as d3 from "d3";
import { mark } from "@angular/compiler-cli/src/ngtsc/perf/src/clock";
import { FilterService } from 'src/app/services/filter.service';
import { CurrencyPipe } from '@angular/common';
import { tooltip } from 'aws-amplify';
@Injectable({
  providedIn: 'root'
})
@Component({
  selector: 'app-grouped-hbar-chart',
  templateUrl: './grouped-hbar-chart.component.html',
  styleUrls: ['./grouped-hbar-chart.component.scss']
})
export class GroupedHbarChartComponent implements OnInit {
  @ViewChild('barContainer', { static: true }) barContainer!: ElementRef
  @ViewChild('WordCloudChart') WordCloudChart!: ElementRef;
  @Input('data') data: any
  @Input('pageKey') pageKey: any
  @Input('item') item: any
  @Input('config') config: any
  @Input('headerConfig') headerConfig: any
  @Input('heading') heading: string = ''
  @ViewChild('fs') fs!: ElementRef;
  isActive = false;
  mytooltipData: any
  iconList: any[] = []
  @HostBinding('class.is-fullscreen') isFullscreen = false;
  GroupedHbarChartData: any;
  props: any;
  divId: any = "GroupedHbarChartDiv";
  initiateChart: Boolean = false
  dataTurn: any
  isTooltip: boolean = false
  noData: boolean = false
  myType: any
  @HostListener('fullscreenchange', ['$event'])
  @HostListener('webkitfullscreenchange', ['$event'])
  @HostListener('mozfullscreenchange', ['$event'])
  @HostListener('MSFullscreenChange', ['$event'])
  screenChange(event: any) {
    if (this.isFullscreen == true) {
      this.isTooltip = false
      this.closeFullscreen();
      // this.isFullscreen = false
    }
  }
  height: any = 0
  constructor(private filterService: FilterService, private currency: CurrencyPipe) { 
    this.filterService.filterQuery.subscribe((query: any) => {
      this.start()
    })
  }



//loade Method
isLoading = false;
async stop(ms: number): Promise<void> {
  return new Promise<void>(resolve => setTimeout(resolve, ms));
}
start() { this.isLoading = true;}

  private showTooltip(myType: any, myData: any, myX: any, myY: any, chartWidth: any, chartHeight: any): void {
    this.myType = myType
    this.mytooltipData = myData
    this.height = 0
    this.dataTurn = 0
    this.height = chartHeight - myY
    this.dataTurn = chartWidth - myX

    if (this.isFullscreen == true) {
      if (myType == 'groupFullName') {
        d3.select("#d3HBarTooltip")
          .style('visibility', 'visible')
          .style('position', 'absolute')
          .style('top', (myY + 45) + 'px')
          .style('left', (myX + 20) + 'px')
          .style('right', 'unset')
      }

      else if (this.height < 200) {
        d3.select("#d3HBarTooltip")
          .style('visibility', 'visible')
          .style('position', 'absolute')
          .style('bottom', (this.height - 60) + 'px')
          .style('top', 'unset')
      }
      else if (this.height > 200) {
        d3.select("#d3HBarTooltip")
          .style('visibility', 'visible')
          .style('position', 'absolute')
          .style('top', (myY + 30) + 'px')
          .style('bottom', 'unset')
      }

      if (this.dataTurn < 250) {
        d3.select("#d3HBarTooltip")
          .style('visibility', 'visible')
          .style('position', 'absolute')
          .style('right', (this.dataTurn + 20) + 'px')
          .style('left', 'unset')
      }
      else if (this.dataTurn > 250) {
        d3.select("#d3HBarTooltip")
          .style('visibility', 'visible')
          .style('position', 'absolute')
          .style('left', (myX + 25) + 'px')
          .style('right', 'unset')
      }
      this.isTooltip = true
    }

    else {
      if (myType == 'groupFullName') {

        d3.select("#d3HBarTooltip")
          .style('visibility', 'visible')
          .style('position', 'absolute')
          .style('top', (myY + 45) + 'px')
          .style('left', (myX + 20) + 'px')
          .style('right', 'unset')
      }

      else if (this.height < 200) {
        d3.select("#d3HBarTooltip")
          .style('visibility', 'visible')
          .style('position', 'absolute')
          .style('bottom', (this.height - 60) + 'px')
          .style('top', 'unset')
      }
      else if (this.height > 200) {

        d3.select("#d3HBarTooltip")
          .style('visibility', 'visible')
          .style('position', 'absolute')
          .style('top', (myY + 30) + 'px')
          .style('bottom', 'unset')
      }

      if (this.dataTurn < 250) {
        d3.select("#d3HBarTooltip")
          .style('visibility', 'visible')
          .style('position', 'absolute')
          .style('right', (this.dataTurn + 20) + 'px')
          .style('left', 'unset')
      }
      else if (this.dataTurn > 250) {

        d3.select("#d3HBarTooltip")
          .style('visibility', 'visible')
          .style('position', 'absolute')
          .style('left', (myX + 25) + 'px')
          .style('right', 'unset')
      }

      this.isTooltip = true
    }


  }

  private hideTooltip(myType: any): void {
    this.isTooltip = false
    // d3.select("#d3HBarTooltip")
    // .style('visibility', 'hidden');
  }


  ngOnInit(): void {
    this.start()
    this.iconList = this.item.config.icon ? this.item.config.icon : this.iconList
    this.initiateCharts();

  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['data'].currentValue != changes['data'].previousValue && this.initiateChart) {
      this.getGroupedHbarChartData();
    }
  }
  closeFullscreen(): void {
    this.isFullscreen = false;
    if (document.fullscreenElement) {
      this.isTooltip = false
      document.exitFullscreen();
    }
    this.props.chartHeight = 500
    this.isActive = false;
    setTimeout(() => {
      this.plotChart()
    }, 100);
  }

  @HostListener("window:resize", ["$event"])
  onResize(event: Event) {
    setTimeout(() => {
      this.plotChart()
    }, 100);
  }
  //  d3 chart initial structure
  initiateCharts(): void {
    // only need to call this once on initialisation
    const myChart = this;
    const myClass = myChart.divId;

    const mySvg = d3.select('#' + myClass)
      .append('svg')
      .attr('id', 'svg_' + myClass)
      .attr('width', '100%')
      .style('background-color', 'white');

    mySvg.append("g").attr("id", "brushGroup" + myClass);
    mySvg.append("g").attr("id", "xAxis" + myClass);
    mySvg.append('text').attr('id', 'xAxisLabel' + myClass);
    mySvg.append('text').attr('id', 'yAxisLabel' + myClass);

    mySvg.append('text').attr('id', 'noDataMessage' + myClass);
    const defs = mySvg.append('defs');

    defs.append('clipPath').attr('id', 'ghbBrushClip' + myClass)
      .append('rect').attr('id', 'ghbBrushClipRect' + myClass);

    const filter = defs.append('filter').attr('id', 'drop-shadow').attr('width', 10).attr('height', 24);
    filter.append('feGaussianBlur').attr('in', 'SourceAlpha').attr('stdDeviation', 1).attr('result', 'blur');
    filter.append('feOffset').attr('in', 'blur').attr('dx', 1)
      .attr('dy', 1).attr('result', 'offsetBlur');
    filter.append('feFlood').attr('in', 'offsetBlur')
      .attr('flood-color', '#000000').attr('flood-opacity', 0.4)
      .attr('result', 'offsetColor');
    filter.append('feComposite').attr('in', 'offsetColor').attr('in2', 'offsetBlur').attr('operator', 'in').attr('result', 'offsetBlur');
    const feMerge = filter.append('feMerge');
    feMerge.append('feMergeNode').attr('in', 'offsetBlur');
    feMerge.append('feMergeNode').attr('in', 'SourceGraphic');

    mySvg.append('line').attr('class', 'handleLines' + myClass + ' handleLeftLine1' + myClass);
    mySvg.append('line').attr('class', 'handleLines' + myClass + ' handleLeftLine2' + myClass);
    mySvg.append('line').attr('class', 'handleLines' + myClass + ' handleRightLine1' + myClass);
    mySvg.append('line').attr('class', 'handleLines' + myClass + ' handleRightLine2' + myClass);
    this.initiateChart = true
  }
// chart svg  plotChart rendering 
  plotChart(): void {
    const myChart = this;
    const myClass = myChart.divId;
    const mySvg: any = d3.select('#svg_' + myClass);
    const width: any = mySvg.node().getBoundingClientRect().width;
    let height = myChart.props.chartHeight < 300 ? 300 : myChart.props.chartHeight;
    const margins = { left: 20, right: 20, top: 40, bottom: 40, group: 4, brush: 52, mid: 24, range: 52 };

    mySvg.attr("height", height);

    let rangeSet: any = new Set();
    let stackSet: any = Object.keys(myChart.props.barFill)
    let groupSet: any = new Set();
    myChart.GroupedHbarChartData.forEach((d: any) => {
      rangeSet.add(d[myChart.props.rangeVar]);
      groupSet.add(d[myChart.props.groupVar]);
    })
    stackSet = Array.from(stackSet);
    rangeSet = Array.from(rangeSet);
    groupSet = Array.from(groupSet);
    const firstGroup = Array.from(d3.group(myChart.GroupedHbarChartData, (d: any) => d[myChart.props.groupVar]));
    const stackData: any = [];
    firstGroup.forEach((d: any) => {
      rangeSet.forEach((r: any) => {
        const myStackObject: any = {
          groupVar: d[0],
          range: r,
        }
        const matchingRows: any = d[1].filter((f: any) => f[myChart.props.groupVar] === d[0] && f[myChart.props.rangeVar] === r);
        stackSet.forEach((s: any) => {
          const myRange = matchingRows.filter((f: any) => f[myChart.props.stackVar] === s);
          if (myRange === undefined) {
            myStackObject[s] = 0;
          } else {
            myStackObject[s] = d3.sum(myRange, (s: any) => s[myChart.props.valueVar]);
          }
        })
        stackData.push(myStackObject);
      })
    })
    const myStack = d3.stack()
      .keys(stackSet)
      .order(d3.stackOrderNone)
      .offset(d3.stackOffsetNone)
      (stackData);
    const allStack: any = [];

    myStack.forEach((d: any) => d.forEach((s: any) => {
      s["key"] = d.key;
      allStack.push(s)
    }));
    let groupedData: any = [];
    let xMax: any = d3.max(allStack, (m: any) => m[1]);
    groupSet.forEach((d: any, i: any) => {
      const myData = allStack.filter((f: any) => f.data.groupVar === d);
      const byRange: any = [];
      rangeSet.forEach((r: any) => {
        byRange.push({
          range: r,
          data: myData.filter((f: any) => f.data.range === r)
        })
      })
      groupedData.push({
        group: d,
        data: byRange,
        groupIndex: i,
        maxVal: d3.max(myData, (m: any) => m[1])
      })
    })
    let visibleGroups = 1;
    const chartWidth = width - margins.left - margins.brush - margins.mid - margins.range - 36 - 24 - margins.right;
    let groupHeight = (22 * (rangeSet.length + 1)) + margins.group;
    let chartHeight = (groupHeight * groupSet.length) + margins.top + margins.bottom;
    if (chartHeight < height) {
      // groupHeight = (height - margins.top - margins.bottom)/groupSet.length;
      //  chartHeight = (groupHeight * groupSet.length) + margins.top + margins.bottom;
    } else {
      //height = chartHeight;
      //mySvg.attr("height", chartHeight);
    }
    const yScaleAll: any = d3.scaleLinear().domain([0, groupSet.length]).range([0, height - margins.top - margins.bottom])
    const yScale: any = d3.scaleLinear().domain([0, groupSet.length]).range([0, height - margins.top - margins.bottom])
    const xScale: any = d3.scaleLinear().domain([0, xMax]).range([0, chartWidth]);
    const xScaleBrush: any = d3.scaleLinear().domain([0, xMax]).range([0, margins.brush - 2])

    const brushRangeHeight = ((height - margins.top - margins.bottom) / groupSet.length) / (rangeSet.length + 2);
    let startingVisibleGroups: any = parseInt(String((height - margins.top - margins.bottom) / groupHeight));
    startingVisibleGroups = startingVisibleGroups > groupSet.length ? groupSet.length : startingVisibleGroups;
    const brushStartY = groupSet.length === 0 ? 0 : (1 - (startingVisibleGroups / groupSet.length))
      * (height - margins.top - margins.bottom);
    groupHeight = (height - margins.top - margins.bottom) / startingVisibleGroups;
    let rangeHeight = (groupHeight * visibleGroups) / (rangeSet.length + 2);
    
    const brush: any = d3.brushY()
      .handleSize(10)
      .extent([[0, 0], [margins.brush, height - margins.top - margins.bottom]])
      .on('start brush end', brushed);

    mySvg.select('#xAxis' + myClass)
    .attr("visibility", xMax === 0 ? "hidden" : "visible")
      // @ts-ignore
      .call(d3.axisBottom(xScale).tickSizeOuter(0).ticks(5))
      .attr('transform', 'translate(' + (width - chartWidth - margins.right) + ',' + (height - margins.bottom) + ')');

    mySvg.selectAll('#xAxis' + myClass + " text")
      .attr("font-family", "Poppins")
      .attr("font-weight", 700)
      .attr("font-size", 12)
      .attr("fill", "#101D42")

    mySvg.select('#xAxis' + myClass + " path")
      .attr("display", "none");

    mySvg.selectAll('#xAxis' + myClass + " line")
      .attr("y1", 0)
      .attr("y2", -(height - margins.top - margins.bottom))
      .style('stroke', '#E8EAEE')
      .style('stroke-width', '1');

    mySvg.select('#ghbBrushClipRect' + myClass)
      .style('width', width - margins.left - margins.right - margins.brush - margins.mid)
      .style('height', height - margins.top - margins.bottom)
      .attr('transform', 'translate(' + (margins.left + margins.brush + margins.mid) + ',' + margins.top + ')');

      const brushChartGroup = mySvg
        .select('#brushGroup' + myClass)
        .selectAll('.brushChartGroup' + myClass)
        .data(groupedData)
        .join((enter:any) => {
          const groupEnter = enter.append('g').attr('class', 'brushChartGroup' + myClass);
          groupEnter.append('g').attr('class', 'brushRangeGroup');
          return groupEnter;
        });

        brushChartGroup
        .select('.brushRangeGroup')
        .attr('transform', (d: any) => `translate(0, ${yScale(d.groupIndex)})`);

        const brushRangeGroup = brushChartGroup
        .select('.brushRangeGroup')
        .selectAll('.brushRangeGroup' + myClass)
        .data((d: any) => {
          d.data.forEach((range: any,rangeindex:any) => {
            range.data.forEach((bar: any, index: number) => {
              const dataWidth = xScaleBrush(bar[1]) - xScaleBrush(bar[0]);
              bar.width = Math.max(dataWidth, 0); // Ensure a minimum width of 2.
                 bar.groupIndex = d.groupIndex
                 bar.rangeindex = rangeindex
              // Adjust subsequent bars if width is too small.
              if (dataWidth < 2 && index < range.data.length - 1) {
                range.data.slice(index + 1).forEach((nextBar: any) => {
                  nextBar.extraX = 0 - dataWidth;
                });
              }

              // Set border radius only for the last bar in the range.
              bar.borderRadius = index === range.data.length - 1 ? 2 : 0;
            });
          });

          // Flatten and return the bar data.
          return d.data.flatMap((range: any) => range.data);
        })
        .join((enter:any) => {
          const barEnter = enter.append('g').attr('class', 'brushRangeGroup' + myClass);
          barEnter.append('path').attr('class', 'brushRangeBar');
          return barEnter;
        });

        brushRangeGroup
        .select('.brushRangeBar')
        .attr('d', (d: any) => {
          // Generate the SVG path for the bar with rounded corners.
          const { width, borderRadius } = d;
          return `
            M0,0 
            L${width - borderRadius},0 
            Q${width},0 ${width},${borderRadius} 
            L${width},${brushRangeHeight - borderRadius} 
            Q${width},${brushRangeHeight} ${width - borderRadius},${brushRangeHeight} 
            L0,${brushRangeHeight} Z
          `;
        })
        .attr('transform', (d: any, i: any) =>
          `translate(${xScaleBrush(d[0]) + (d.extraX || 0)}, ${brushRangeHeight * (d['groupIndex'] + d['rangeindex'])})`
        )

        .attr('fill', (d: any) => myChart.props.barFill[d.key]);


    mySvg.select('#brushGroup' + myClass)
      .attr('transform', 'translate(' + margins.left + ',' + margins.top + ')')
      //@ts-ignore
      .call(brush)
      // .call(brush.move, [brushStartY, height - margins.top - margins.bottom]);
      .call(brush.move, [ 0, (height - margins.top - margins.bottom)-brushStartY]);

    mySvg.selectAll('.handleLines' + myClass)
      .attr('visibility', myChart.GroupedHbarChartData.length === 0 ? 'hidden' : 'visible')
      .attr('x1', margins.left + (margins.brush - 12) / 2)
      .attr('x2', margins.left + 12 + (margins.brush - 12) / 2)
      .style('stroke', '#8A98AB')
      .style('stroke-width', '1')
      .attr('transform', 'translate(0,' + margins.top + ')');
       // axis labels
       mySvg.select('#xAxisLabel' + myClass)
       .attr('visibility',  'visible')
       .attr('x', margins.left + ((width - margins.left - margins.right)/2))
       .attr('y', height-3  )
       .attr('text-anchor', 'middle')
       .attr('font-family', 'Poppins')
       .attr('fill', '#737D88')
       .attr('font-size', '12px')
       // @ts-ignore
       .text(myChart.props.xAxisLabel);

       mySvg.select('#yAxisLabel' + myClass)
       .attr('visibility',  'visible')
       .attr('transform','translate(10,' + (margins.top + ((height - margins.top - margins.bottom - margins.mid - margins.brush)/2)) + ') rotate(-90)')
       .attr('text-anchor', 'middle')
       .attr('font-family', 'Poppins')
       .attr('fill', '#737D88')
       .attr('font-size', '12px')
       // @ts-ignore
       .text(myChart.props.yAxisLabel);

    mySvg.selectAll('#brushGroup' + myClass)
      .selectAll('.selection')
      .attr('fill', '#A0A0A0')
      .attr('fill-opacity', '0.09')
      .style('stroke', '#E8EAEE')
      .style('stroke-width', '1');

    drawLegend(Object.keys(myChart.props.barFill));
    drawChart();
    function drawChart() {
      groupHeight = (height - margins.top - margins.bottom) / visibleGroups;
      rangeHeight = groupHeight / (rangeSet.length + 2);

      mySvg.selectAll(".selection")
        .attr('visibility', myChart.GroupedHbarChartData.length === 0 ? 'hidden' : 'visible');

      const chartGroup = mySvg
        .selectAll('.chartGroup' + myClass)
        .data(groupedData)
        .join((group: any) => {
          const enter = group.append('g').attr('class', 'chartGroup' + myClass);
          enter.append('rect').attr('class', 'groupRect');
          enter.append('line').attr('class', 'groupTopLine');
          enter.append('line').attr('class', 'groupBottomLine');
          enter.append('text').attr('class', 'groupLabel');
          enter.append('g').attr('class', 'rangeGroup');
          return enter;
        });

      chartGroup.attr('clip-path', 'url(#ghbBrushClip' + myClass + ')');

      chartGroup.select('.groupLabel')
        .attr("font-family", "Poppins")
        .attr("font-weight", 700)
        .attr("font-size", 12)
        .attr("fill", "white")
        .attr('text-anchor', 'middle')
        .attr('transform', (d: any) => 'translate('
          + (margins.left + margins.brush + margins.mid + 18 + 5)
          + ',' + (yScale(d.groupIndex) + margins.top + (groupHeight - margins.group) / 2) + ') rotate(-90)')
        .text(getGroupLabel)
        .on("mouseover", (event: any, d: any) => {
          if (getGroupLabel(d).includes("..")) {
            myChart.showTooltip('groupFullName', d.group.toUpperCase(), event.offsetX, event.offsetY, width, height);
          }
        })
        .on("mouseout", () => {
          myChart.hideTooltip("groupFullName");
        });

      chartGroup.select('.groupTopLine')
        .style('stroke-width', 1)
        .style('stroke', '#E8EAEE')
        .attr('x1', margins.left + margins.brush + margins.mid + 36 + margins.range + 10)
        .attr('x2', margins.left + margins.brush + margins.mid + 36)
        .attr('y1', (d: any) => yScale(d.groupIndex) + margins.top)
        .attr('y2', (d: any) => yScale(d.groupIndex) + margins.top);

      chartGroup.select('.groupBottomLine')
        .style('stroke-width', 1)
        .style('stroke', '#E8EAEE')
        .attr('x1', margins.left + margins.brush + margins.mid + 36 + margins.range + 10)
        .attr('x2', margins.left + margins.brush + margins.mid + 36)
        .attr('y1', (d: any) => yScale(d.groupIndex) + margins.top + (groupHeight - margins.group))
        .attr('y2', (d: any) => yScale(d.groupIndex) + margins.top + (groupHeight - margins.group));

      chartGroup.select('.groupRect')
        .style('width', 36)
        .style('height', groupHeight - margins.group)
        .style('fill', '#8A98AB')
        .attr('transform', (d: any) => 'translate(' + (margins.left + margins.brush + margins.mid) + ',' + (yScale(d.groupIndex) + margins.top) + ')');

      chartGroup.select('.rangeGroup')
        .attr('transform', (d: any) => 'translate(' + (margins.left + margins.brush + margins.mid + 36) + ',' + (yScale(d.groupIndex)
          + margins.top + rangeHeight) + ')');

      const rangeGroup = chartGroup
        .select('.rangeGroup')
        .selectAll('.rangeGroup' + myClass)
        .data((d: any) => d.data)
        .join((group: any) => {
          const enter = group.append('g').attr('class', 'rangeGroup' + myClass);
          enter.append('text').attr('class', 'rangeLabel');
          enter.append('g').attr('class', 'rangeBarGroup');
          enter.append('rect').attr('class', 'rangeMouseoverRect');
          return enter;
        });

      rangeGroup.select(".rangeMouseoverRect")
        .attr("fill", "transparent")
        .attr("width", (d: any) => xScale(d3.max(d.data, (m: any) => m[1])))
        .attr("height", rangeHeight)
        .attr('x', margins.range + 24)
        .attr('y', (d: any, i: any) => (i * rangeHeight))
        .on("mouseover", (event: any, d: any) => {
          const tooltipData: any = [];
          d.data.forEach((t: any) => {
            tooltipData.push({
              group: t.data.groupVar,
              range: d.range,
              key: t.key,
              value: t.data[t.key]
            })
          })
          myChart.showTooltip('groupHBar', tooltipData, event.offsetX, event.offsetY, width, height);
        })
        .on('mouseout', () => {
          myChart.hideTooltip('groupHBar');
        });

      rangeGroup.select(".rangeLabel")
        .attr("font-family", "Poppins")
        .attr("font-size", 12)
        .attr("font-weight", 700)
        .attr("fill", "#101D42")
        .attr('text-anchor', 'end')
        .attr('x', margins.range)
        .attr('y', (d: any, i: any) => (i * rangeHeight) + (rangeHeight / 2) + 5)
        .text((d: any) => d.range)

      rangeGroup.select(".rangeBarGroup")
        .attr("transform", (d: any, i: any) => "translate(" + (margins.range + 24) + ","
          + (i * rangeHeight) + ")")

      const rangeBarGroup = rangeGroup.select(".rangeBarGroup")
        .selectAll('.rangeBarGroup' + myClass)
        .data((d: any) => {
          d.data.map((m: any, i: any) => {
            const dataWidth = xScale(m[1]) - xScale(m[0]);
            m.width = dataWidth < 2 && dataWidth !== 0 ? 2 : dataWidth;
            if(dataWidth < 2 && i < (d.data.length - 1)){
              d.data.filter((f: any, index: any) => index > i).forEach((m: any) => {
                m.extraX = dataWidth === 0 ? 0 : 2 - dataWidth;
              })
            }
          });

          d.data.map((m: any, i: any) => m.borderRadius = i === (d.data.length - 1) ? 2 : 0)
          return d.data
        })
        .join((group: any) => {
          const enter = group.append('g').attr('class', 'rangeBarGroup' + myClass);
          enter.append('path').attr('class', 'rangeBar');
          return enter;
        });


      rangeBarGroup.select(".rangeBar")
        .attr("d", (d: any) => "M0,0 L"
          + (d.width - d.borderRadius) +
          ",0 Q" + d.width
          + ",0  " + d.width + "," + d.borderRadius + " L " + d.width
          + "," + (rangeHeight - d.borderRadius - 2) + " Q " + d.width + "," + + (rangeHeight - 2) + " "
          + (d.width - d.borderRadius) +
          "," + (rangeHeight - 2) + " L 0," + (rangeHeight - 2) + " Z")
        .attr("transform", (d: any) => "translate(" + (xScale(d[0]) + (d.extraX === undefined ? 0 : d.extraX)) +
          ",0)")
        .attr("fill", (d: any) => myChart.props.barFill[d.key])
    }

    function drawLegend(legendData: any) {

      const legendGroup = mySvg
        .selectAll('.legendGroup' + myClass)
        .data(legendData)
        .join((group: any) => {
          const enter = group.append('g').attr('class', 'legendGroup' + myClass);
          enter.append('circle').attr('class', 'legendCircle');
          enter.append('text').attr('class', 'legendLabel');
          return enter;
        });

      legendGroup.attr("transform", "translate(" + (margins.left + margins.brush + margins.mid + 36) + ",15)");

      legendGroup.select('.legendCircle')
        .attr('id', (d: any) => 'circle_legendItem' + d)
        .attr('fill', (d: any) => myChart.props.barFill[d])
        .attr('r', 4);

      legendGroup.select('.legendLabel')
        .attr('id', (d: any) => 'legendItem' + d)
        .attr('y', 5)
        .attr('fill', '#101D42')
        .attr('font-weight', '500')
        .attr('font-size', '12')
        .attr('font-family', 'Poppins')
        .text((d: any) => myChart.props.barLabels[d].toUpperCase())

      let legendX = 0;
      mySvg.selectAll('.legendLabel').each(function (d: any): any {
        //@ts-ignore
        const myObject = this;
        const myWidth: any = document.getElementById(myObject.id)?.getBoundingClientRect().width;
        d3.select(myObject).attr('x', legendX + 12);
        mySvg.select('#circle_' + myObject.id).attr('cx', legendX);
        legendX += (40 + myWidth)
      });



    }

    function getGroupLabel(d: any) {
      let myLabel = d.group.toUpperCase();
      while (!checkWidth(myLabel) || myLabel === "..") {
        if (myLabel.includes("..")) {
          myLabel = myLabel.substr(0, myLabel.length - 2);
        }
        myLabel = myLabel.substr(0, myLabel.length - 1) + "..";
      }
      return myLabel;
      function checkWidth(myText: any) {
        if ((measureWidth(myText, 12) + 4) > groupHeight) {
          return false
        } else {
          return true
        }
      }

    }
    function measureWidth(myText: any, myFontSize: any): any {
      const context: any = document.createElement('canvas').getContext('2d');
      context.font = myFontSize + 'px sans-serif';
      return context.measureText(myText).width;
    }
    function brushed(event: any): void {

      const selection = event.selection;
      if (selection !== null) {
        // myChart.hideTooltip('groupHBar');
        // myChart.hideTooltip("groupFullName");
        const yDomain: any = selection.map(yScaleAll.invert);
        if ((yDomain[1] - yDomain[0]) < 0.99) {
          if ((yDomain[0] + 1) > groupSet.length) {
            yDomain[0] = yDomain[1] - 1;
          } else {
            yDomain[1] = yDomain[0] + 1;
          }
          mySvg.select('#brushGroup' + myClass)
            //@ts-ignore
            .call(brush.move, myChart.props.showBrush === false ? null : [yScaleAll(yDomain[0]), yScaleAll(yDomain[1])]);
        } else {
          visibleGroups = ((yDomain[1] - yDomain[0]) / groupSet.length) * groupSet.length;
          yScale.domain(yDomain);

          drawChart();

          const handleSouthY = +mySvg.select('#brushGroup' + myClass).select('.handle--s').attr('y');
          const handleNorthY = +mySvg.select('#brushGroup' + myClass).select('.handle--n').attr('y');

          mySvg.select('.handleLeftLine1' + myClass)
            .attr('y1', handleSouthY + 3)
            .attr('y2', handleSouthY + 3);

          mySvg.select('.handleLeftLine2' + myClass)
            .attr('y1', handleSouthY + 7)
            .attr('y2', handleSouthY + 7);

          mySvg.select('.handleRightLine1' + myClass)
            .attr('y1', handleNorthY + 3)
            .attr('y2', handleNorthY + 3);

          mySvg.select('.handleRightLine2' + myClass)
            .attr('y1', handleNorthY + 7)
            .attr('y2', handleNorthY + 7);

          mySvg.select('#brushGroup' + myClass)
            .selectAll('.handle')
            .attr('fill', 'white')
            .attr('rx', 4)
            .attr('ry', 4)
            .attr('x', (margins.brush - 24) / 2)
            .attr('width', 24)
            .style('filter', 'url(#drop-shadow)');
        }
      }
    }
    this.stop(10).then(() => this.isLoading = false);
    // so now
    // get horizontal brush working with just groups
    // then add bars and range labels?
  }
// property & data for the chart
  getGroupedHbarChartData() {
    //  this.filterService.getSampleJsonData("defect_sp_by_range").subscribe((res:any)=>{
    this.GroupedHbarChartData = this.data
    this.props = {
      chartHeight: 500,
      rangeVar: 'range_nm',
      stackVar: 'error_type_code',
      groupVar: 'data_source_name',// (this.item.config.list_of_api == 100175) ? 'file_type_name' :
      valueVar: 'defect_count',
      xAxisLabel:  this.item.config?.['x_axis']?.["api_resp_column_display_nm"]?.toUpperCase() || 'DEFECT COUNT',
      yAxisLabel:  this.item.config?.['y_axis']?.["api_resp_column_display_nm"]?.toUpperCase() || 'SOURCE NAME',
      barFill: { 'F': '#FF842B', 'E': '#FFD953' },
      barLabels: { 'F': 'Failures', 'E': 'Errors' },
      outlierMultiple: 4
    }
    // const allZero = this.GroupedHbarChartData.filter((f: any) => f[this.props.valueVar] > 0).length === 0;
    // if(allZero){
    //   this.GroupedHbarChartData = undefined;
    // }
     if (this.isFullscreen) {
      this.props.chartHeight = window.outerHeight - 60
    }
    // @ts-ignore
    if ( this.GroupedHbarChartData.length > 0) {
      this.noData=false
      setTimeout(() => {
        
        this.plotChart();
      }, 100);
    }else{
      this.isLoading=false
      this.noData=true
      this.isLoading = false;
    }
    // });
  }
  // numberFormat
  numbedPipe(value: any) {
    return this.currency.transform(value, '', '', '1.0-2');
    //  return   this.numberpipe.transform(value)
  }
}
