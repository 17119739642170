import { Component, ElementRef, HostBinding, HostListener, Injectable, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import * as d3 from "d3";
import { FilterService } from 'src/app/services/filter.service';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { NavigationService } from 'src/app/services/navigation.service';
import { RouterHandlerService } from 'src/app/services/router-handler.service';
import { CurrencyPipe } from '@angular/common';

@Injectable({
  providedIn: 'root'
})

@Component({
  selector: 'app-horizontal-bar-chart',
  templateUrl: './horizontal-bar-chart.component.html',
  styleUrls: ['./horizontal-bar-chart.component.scss']
})
export class horizontalHarChartComponent implements OnInit {
  
  @ViewChild('wordCloudContainer', { static: true }) wordCloudContainer!: ElementRef;
  @ViewChild('WordCloudChart') WordCloudChart!: ElementRef;
  @ViewChild('fs') fs!: ElementRef;
  @HostBinding('class.is-fullscreen') isFullscreen = false;

  @Input('data') data: any;
  @Input('pageKey') pageKey: any;
  @Input('item') item: any;
  @Input('config') config: any;
  @Input('headerConfig') headerConfig: any;
  @Input('heading') heading: string = '';
  
  isActive = false;
  iconList: any[] = [];
  HbarChartData:any;
  props: any;
  divId: any ='HbarChartDiv'// "WordCloudChartDiv";
  headerDivId: any = "HbarHeader";
  initiateChart:Boolean=false;
  noData: boolean = false;
  mytooltipData:any;
  dataTurn:any;
  tooltip:any;
  tooltipType:any;
  clientKey: any;
  moduleKey: any;
  dashboardKey: any;
  cardName: any;
  cardId: any;
  moduleName:any;
  decryptedParams:any;
  subs: Subscription[] = [];
  isLoading = false;
  height:any;

  @HostListener('fullscreenchange', ['$event'])
  @HostListener('webkitfullscreenchange', ['$event'])
  @HostListener('mozfullscreenchange', ['$event'])
  @HostListener('MSFullscreenChange', ['$event'])
  screenChange(event:any) {
    if (this.isFullscreen == true) {
      this.closeFullscreen();
    }
  }
  
  constructor(
    private filterService :FilterService, 
    private route:ActivatedRoute, 
    private navigationService: NavigationService,
    private routerHandlerService: RouterHandlerService,
    private currency: CurrencyPipe
  ) {
    this.subs.push(
      this.filterService.filterQuery.subscribe((query: any) => {
      this.start();
    }))

    this.route.params.subscribe((p: any) => {
      this.moduleName = p['module'] || '';
      this.cardName = p['cardName'] || '';

      this.route.queryParams.subscribe((params: any) => {
        this.decryptedParams = this.navigationService.decryptData(params);
        this.clientKey = this.decryptedParams["cl_key"];
        this.moduleKey = this.decryptedParams["md_key"];
        this.dashboardKey = this.decryptedParams["ds_key"];
      });
    });
  }
  
  numbedPipe(value: any) {
    return this.currency.transform(value, '', '', '1.0-2');
  }

  async stop(ms: number): Promise<void> {
    return new Promise<void>(resolve => setTimeout(resolve, ms));
  }

  start() { this.isLoading = true;}

  private NavigationArrow(myType: any, myData: any, myX: any, myY: any, chartWidth: any): void {
  
  const conf0 = this.item.config?.['nav_configuration'].controls[0]
    let nav = {
      dbJSON : conf0.dbJSON,
      s3JSON : conf0.s3JSON,
      pageName : conf0.s3JSON?.page_nm,
      isDashboardLevel : conf0.isDashboardLevel
    }
    let payload:any ={source_nm:myData[0].range}
    this.routerHandlerService.navDetailInfo(nav); 
    this.routerHandlerService.storingPayloadAndFilterForGrid(conf0, myData, this.cardName);

    let query:any = Object.assign({}, this.decryptedParams, {tab_id: 0}, {page: 'card'});
    let queryMaped = this.navigationService.movePropertyToFirst(query, 'tab_id');
    this.navigationService.routerNavigate(`dashboard/${this.moduleName}/${nav.pageName}`, queryMaped);
  }

  private showTooltip(myType: any, myData: any, myX: any, myY: any, chartWidth: any, chartHeight:any): void {
    this.tooltipType=myType
    this.mytooltipData =this.tooltipType=='groupHBar'? myData:myData
    this.dataTurn = 0;
    this.height = 0;
    this.dataTurn = chartWidth - myX
    this.height = chartHeight - myY
    

    if (this.height < 200) {
      d3.select("#d3WordCloudTooltip")
        .style('visibility', 'visible')
        .style('position', 'absolute')
        .style('bottom', (this.height - 60) + 'px')
        .style('top', 'unset')
    }
    else if (this.height > 200) {

      d3.select("#d3WordCloudTooltip")
        .style('visibility', 'visible')
        .style('position', 'absolute')
        .style('top', (myY + 30) + 'px')
        .style('bottom', 'unset')
    }

    if (this.dataTurn < 250) {
      d3.select("#d3WordCloudTooltip")
        .style('visibility', 'visible')
        .style('position', 'absolute')
        // .style('top', myY + 40 + 'px')
        .style('right', (this.dataTurn + 30) + 'px')
        .style('left', 'unset')
        // .style('bottom', 'unset')
    }
    else if (this.dataTurn > 250) {

      d3.select("#d3WordCloudTooltip")
        .style('visibility', 'visible')
        .style('position', 'absolute')
        // .style('top', (  myY + 40) + 'px')
        .style('left', (myX+30) + 'px')
        .style('right', 'unset')
        // .style('bottom', 'unset')
    }
    this.tooltip=true;
  }

  private hideTooltip(myType: any): void {
    this.tooltip = false;
    d3.select("#d3WordCloudTooltip") 
      .style('visibility', 'hidden');
  }
  
  ngOnInit(): void {
    this.start()
    this.iconList = this.item.config.icon ? this.item.config.icon : this.iconList
    this.initiateCharts();
    // this.getWordCloudData();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['data'].currentValue != changes['data'].previousValue && this.initiateChart) {
      this.getWordCloudData();
    }
  }

  closeFullscreen(): void {
    this.isFullscreen = false;
    if (document.fullscreenElement) {
      document.exitFullscreen();
    } 
    this.props.chartHeight=this.item.config?.y_axis_nav?.value=='true'? 475 : 500
    this.isActive = false;
    setTimeout(() => {
      this.plotChart()
    }, 100);
  }

  @HostListener("window:resize", ["$event"])
  onResize(event: Event) {
    setTimeout(() => {
      this.plotChart()
    }, 100);
  }

  // d3 chart initial structure
  initiateCharts(): void {
     // only need to call this once on initialisation
     const myChart = this;
     const myClass = myChart.divId;
 
     const mySvg = d3.select(this.wordCloudContainer.nativeElement)
       .append('svg')
       .attr('id', 'svg_' + myClass)
       .attr('width', '100%')
       .style('background-color', 'white');
 
       mySvg.append("g").attr("id", "brushGroup" + myClass);
       mySvg.append("g").attr("id", "xAxis" + myClass);
       mySvg.append('text').attr('id', 'xAxisLabel' + myClass);
       mySvg.append('text').attr('id', 'yAxisLabel' + myClass);
   
       mySvg.append('text').attr('id', 'noDataMessage' + myClass);
       const defs = mySvg.append('defs');
   
       defs.append('clipPath').attr('id', 'ghbBrushClip' + myClass)
         .append('rect').attr('id', 'ghbBrushClipRect' + myClass);
   
       const filter = defs.append('filter').attr('id', 'drop-shadow').attr('width', 10).attr('height', 24);
       filter.append('feGaussianBlur').attr('in', 'SourceAlpha').attr('stdDeviation', 1).attr('result', 'blur');
       filter.append('feOffset').attr('in', 'blur').attr('dx', 1)
         .attr('dy', 1).attr('result', 'offsetBlur');
       filter.append('feFlood').attr('in', 'offsetBlur')
         .attr('flood-color', '#000000').attr('flood-opacity', 0.4)
         .attr('result', 'offsetColor');
       filter.append('feComposite').attr('in', 'offsetColor').attr('in2', 'offsetBlur').attr('operator', 'in').attr('result', 'offsetBlur');
       const feMerge = filter.append('feMerge');
       feMerge.append('feMergeNode').attr('in', 'offsetBlur');
       feMerge.append('feMergeNode').attr('in', 'SourceGraphic');
   
       mySvg.append('line').attr('class', 'handleLines' + myClass + ' handleLeftLine1' + myClass);
       mySvg.append('line').attr('class', 'handleLines' + myClass + ' handleLeftLine2' + myClass);
       mySvg.append('line').attr('class', 'handleLines' + myClass + ' handleRightLine1' + myClass);
       mySvg.append('line').attr('class', 'handleLines' + myClass + ' handleRightLine2' + myClass);
    this.initiateChart=true
  }
  
  // chart svg  plotChart rendering 
  plotChart(): void {
      const myChart:any = this;
      const myClass = myChart.divId;
      const mySvg: any = d3.select('#svg_' + myClass);
      const width: any = mySvg.node().getBoundingClientRect().width;
      let height = myChart.props?.chartHeight < 300 ? 300 : myChart.props.chartHeight;
      const margins = { left: 20, right: 20, top: 40, bottom: 40, group: 4, brush: 52, mid: -18, range: 52} ;

      mySvg.attr("height", height);
    if(myChart.props.navArrow){margins.range=62,margins.brush= 52}
      let rangeSet: any = new Set ();
      let stackSet: any = Object.keys(myChart.props.barFill)
      let groupSet: any = new Set ();
      myChart.HbarChartData.forEach((d: any) => {
        rangeSet.add(d[myChart.props.rangeVar]);
        groupSet.add(d[myChart.props.groupVar]);
      })
      stackSet = Array.from(stackSet);
      rangeSet = Array.from(rangeSet);
      groupSet = Array.from(groupSet);
      const firstGroup = Array.from(d3.group(myChart.HbarChartData, (d: any) => d[myChart.props.groupVar]));
      const stackData: any = [];
      firstGroup.forEach((d: any) => {
        rangeSet.forEach((r: any) => {
          const myStackObject: any = {
            groupVar: d[0],
            range: r,
          }
          const matchingRows: any = d[1].filter((f: any) => f[myChart.props.groupVar] === d[0] && f[myChart.props.rangeVar] === r);
          stackSet.forEach((s: any) => {
            const myRange = matchingRows.find((f: any) => f[myChart.props.stackVar] === s);
            if(myRange === undefined){
              myStackObject[s] = 0;
            } else {
              myStackObject[s] = +myRange[myChart.props.valueVar];
            }
          })
          stackData.push(myStackObject);
        })
      })
      const myStack = d3.stack()
        .keys(stackSet)
        .order(d3.stackOrderNone)
        .offset(d3.stackOffsetNone)
        (stackData);
      const allStack: any = [];

      myStack.forEach((d: any) => d.forEach((s: any) => {
        s["key"] = d.key;
        allStack.push(s)
      }));
      let groupedData: any = [];
      let xMax: any = d3.max(allStack, (m: any) => m[1]);
      groupSet.forEach((d: any, i: any) => {
        const myData = allStack.filter((f: any) => f.data.groupVar === d);
        const byRange: any = [];
        rangeSet.forEach((r: any) => {
          byRange.push({
            range:  d,
            data: myData.filter((f: any) => f.data.range === r)
          })
        })
        groupedData.push({
          group: d,
          data: byRange,
          groupIndex: i,
          maxVal: d3.max(myData, (m: any) => m[1])
        })
      })
      let visibleGroups = 1;
      const chartWidth = width - margins.left - margins.brush - margins.mid - margins.range - 36 - 24 - margins.right;
      let groupHeight = (15 * (rangeSet.length + 1)) + margins.group;
      let chartHeight = (groupHeight * groupSet.length) + margins.top + margins.bottom;
      if(chartHeight < height){
      // groupHeight = (height - margins.top - margins.bottom)/groupSet.length;
      //  chartHeight = (groupHeight * groupSet.length) + margins.top + margins.bottom;
      } else {
        //height = chartHeight;
        //mySvg.attr("height", chartHeight);
      }
      
      const yScaleAll: any = d3.scaleLinear().domain([0,groupSet.length]).range([0, height - margins.top - margins.bottom])
      const yScale: any = d3.scaleLinear().domain([0,groupSet.length]).range([0, height - margins.top - margins.bottom])
      const xScale: any = d3.scaleLinear().domain([0,xMax]).range([0,chartWidth]);
      const xScaleBrush: any = d3.scaleLinear().domain([0,xMax]).range([0,margins.brush-2])

      const brushRangeHeight = ((height - margins.top - margins.bottom)/groupSet.length)/(rangeSet.length + 2) + 3;
      let startingVisibleGroups: any = parseInt(String((height - margins.top - margins.bottom) / groupHeight+ 6));
      // console.log(startingVisibleGroups);
      
      startingVisibleGroups = startingVisibleGroups > groupSet.length ? groupSet.length : startingVisibleGroups;
      const brushStartY = groupSet.length === 0 ? 0 : (1-(startingVisibleGroups/groupSet.length))
        * (height - margins.top - margins.bottom);
      groupHeight = (height - margins.top - margins.bottom) / startingVisibleGroups;
      
      let rangeHeight = (groupHeight * visibleGroups)/(rangeSet.length);
      const brush: any = d3.brushY()
        .handleSize(10)
        .extent([[0, 0],[margins.brush, height - margins.top - margins.bottom]])
        .on('start brush end', brushed);

      mySvg.select('#xAxis' + myClass)
        .attr("visibility", xMax === 0 ? "hidden" : "visible")
        // @ts-ignore
        .call(d3.axisBottom(xScale).tickSizeOuter(0).ticks(myChart.props. XtickCount))
        .attr('transform', 'translate(' + (width - chartWidth - margins.right) + ',' + (height - margins.bottom) + ')');

      mySvg.selectAll('#xAxis' + myClass + " text")
        .attr("font-family", "Poppins")
        .attr("font-weight", 700)
        .attr("font-size", 12)
        .attr("fill", "#101D42")

      mySvg.select('#xAxis' + myClass + " path")
        .attr("display", "none");

      mySvg.selectAll('#xAxis' + myClass + " line")
        .attr("y1", 0)
        .attr("y2", -(height - margins.top - margins.bottom))
        .style('stroke', '#E8EAEE')
        .style('stroke-width', '1');

      mySvg.select('#ghbBrushClipRect' + myClass)
        .style('width', width - margins.left - margins.right - margins.brush - margins.mid)
        .style('height', height - margins.top - margins.bottom)
        .attr('transform', 'translate(' + (margins.left + margins.brush + margins.mid) + ',' + margins.top + ')');
        
      const brushChartGroup =  mySvg.select('#brushGroup' + myClass)
        .selectAll('.brushChartGroup' + myClass)
        .data(groupedData)
        .join((group: any) => {
          const enter = group.append('g').attr('class', 'brushChartGroup' + myClass);
          enter.append('g').attr('class', 'brushRangeGroup');
          return enter;
        });

      brushChartGroup.select('.brushRangeGroup')
        .attr('transform', (d: any, i: any) => 'translate(0,' + (yScale(d.groupIndex)-4) + ')');
        
// debugger
      const brushRangeGroup =  brushChartGroup
        .select('.brushRangeGroup')
        .selectAll('.brushRangeGroup' + myClass)
        .data((d: any) => {
          d.data[0].data.map((m: any, i: any) => {
            const dataWidth = xScaleBrush(m[1]) - xScaleBrush(m[0]);
            m.width = dataWidth < 2 && dataWidth !== 0 ? 2 : dataWidth;
            if(dataWidth < 2 && i < (d.data.length - 1)){
              d.data[0].data.filter((f: any, index: any) => index > i).forEach((m: any) => {
                m.extraX = dataWidth === 0 ? 0 : 2 - dataWidth;
              })
            }
          });

          d.data[0].data.map((m:any, i: any) => m.borderRadius = i === (d.data.length - 1) ? 0 : 0)
          return d.data[0].data
        })
        .join((group: any) => {
          const enter = group.append('g').attr('class', 'brushRangeGroup' + myClass);
          enter.append('path').attr('class', 'brushRangeBar');
          return enter;
        });
        brushRangeGroup.select(".brushRangeBar")
        .attr("d", (d: any) =>  "M0,0 L"
          + (d.width - d.borderRadius) +
          ",0 Q"+ d.width
          + ",0  " + d.width + "," + d.borderRadius + " L " + d.width
          + "," + (brushRangeHeight - d.borderRadius) + " Q " + d.width + "," + + (brushRangeHeight) + " "
          + (d.width - d.borderRadius) +
          "," +  (brushRangeHeight) +" L 0," +  (brushRangeHeight ) + " Z")
        .attr("transform", (d: any) => "translate(" + (xScaleBrush(d[0]) + (d.extraX === undefined ? 0 : d.extraX)) +
        ",12)")
        .attr("fill", (d: any) => myChart.props.barFill[d.key])
    
      mySvg.select('#brushGroup' + myClass)
        .attr('transform', 'translate(' + margins.left + ',' + margins.top + ')')
        //@ts-ignore
        .call(brush)
        .call(brush.move, [ 0, (height - margins.top - margins.bottom)-brushStartY]);

      mySvg.selectAll('.handleLines' + myClass)
        .attr('visibility', myChart.HbarChartData.length === 0 ? 'hidden' : 'visible')
        .attr('x1', margins.left + (margins.brush - 12) / 2)
        .attr('x2', margins.left + 12 + (margins.brush - 12) / 2)
        .style('stroke', '#8A98AB')
        .style('stroke-width', '1')
        .attr('transform', 'translate(0,' + margins.top + ')');
         // axis labels
        mySvg.select('#xAxisLabel' + myClass)
        .attr('visibility',  'visible')
        .attr('x', margins.left + ((width - margins.left - margins.right)/2))
        .attr('y', height-3  )
        .attr('text-anchor', 'middle')
        .attr('font-family', 'Poppins')
        .attr('fill', '#737D88')
        .attr('font-size', '12px')
        // @ts-ignore
        .text(myChart.props.xAxisLabel);

        mySvg.select('#yAxisLabel' + myClass)
        .attr('visibility',  'visible')
        .attr('transform','translate(10,' + (margins.top + ((height - margins.top - margins.bottom - margins.mid - margins.brush)/2)) + ') rotate(-90)')
        .attr('text-anchor', 'middle')
        .attr('font-family', 'Poppins')
        .attr('fill', '#737D88')
        .attr('font-size', '12px')
        // @ts-ignore
        .text(myChart.props.yAxisLabel);

      mySvg.selectAll('#brushGroup' + myClass)
        .selectAll('.selection')
        .attr('fill', '#A0A0A0')
        .attr('fill-opacity', '0.09')
        .style('stroke', '#E8EAEE')
        .style('stroke-width', '1');

      drawLegend(Object.keys(myChart.props.barFill));
      drawChart();
      function drawChart(){
        groupHeight = (height - margins.top - margins.bottom) / visibleGroups;
        rangeHeight = groupHeight/(rangeSet.length + 2);

        mySvg.selectAll(".selection")
          .attr('visibility', myChart.HbarChartData.length === 0 ? 'hidden' : 'visible');

        const chartGroup = mySvg
          .selectAll('.chartGroup' + myClass)
          .data(groupedData)
          .join((group: any) => {
            const enter = group.append('g').attr('class', 'chartGroup' + myClass);
            // enter.append('rect').attr('class', 'groupRect');
            // enter.append('line').attr('class', 'groupTopLine');
            // enter.append('line').attr('class', 'groupBottomLine');
            // enter.append('text').attr('class', 'groupLabel');
            enter.append('g').attr('class', 'rangeGroup');
            return enter;
          });

        chartGroup.attr('clip-path', 'url(#ghbBrushClip' + myClass + ')');

        // chartGroup.select('.groupLabel')
        //   .attr("font-family", "Poppins")
        //   .attr("font-weight", 700)
        //   .attr("font-size", 12)
        //   .attr("fill", "white")
        //   .attr('text-anchor','middle')
        //   .attr('transform', (d: any) => 'translate('
        //     + (margins.left + margins.brush + margins.mid + 18 + 5)
        //     + ',' + (yScale(d.groupIndex) + margins.top + (groupHeight - margins.group)/2) + ') rotate(-90)')
        //   .text(getGroupLabel)
        //   .on("mouseover", (event:any, d: any) => {
        //     if(getGroupLabel(d).includes("..")){
        //       myChart.showTooltip('groupFullName', d.group.toUpperCase(), event.offsetX, event.offsetY, width);
        //     }
        //   })
        //   .on("mouseout", () => {
        //     myChart.hideTooltip("groupFullName");
        //   });

        // chartGroup.select('.groupTopLine')
        //   .style('stroke-width', 1)
        //   .style('stroke', '#E8EAEE')
        //   .attr('x1', margins.left + margins.brush + margins.mid + 36 + margins.range + 10)
        //   .attr('x2', margins.left + margins.brush + margins.mid + 36)
        //   .attr('y1', (d: any) => yScale(d.groupIndex) + margins.top)
        //   .attr('y2', (d: any) => yScale(d.groupIndex) + margins.top);

        // chartGroup.select('.groupBottomLine')
        //   .style('stroke-width', 1)
        //   .style('stroke', '#E8EAEE')
        //   .attr('x1', margins.left + margins.brush + margins.mid + 36 + margins.range + 10)
        //   .attr('x2', margins.left + margins.brush + margins.mid + 36)
        //   .attr('y1', (d: any) => yScale(d.groupIndex) + margins.top + (groupHeight - margins.group))
        //   .attr('y2', (d: any) => yScale(d.groupIndex) + margins.top + (groupHeight - margins.group));

        // chartGroup.select('.groupRect')
        //   .style('width', 36)
        //   .style('height', groupHeight  - margins.group)
        //   .style('fill', '#8A98AB')
        //   .attr('transform', (d: any) => 'translate(' + (margins.left + margins.brush + margins.mid) + ',' + (yScale(d.groupIndex) + margins.top) + ')');

        chartGroup.select('.rangeGroup')
          .attr('transform', (d: any) => 'translate(' + (margins.left + margins.brush + margins.mid + 36) + ',' + (yScale(d.groupIndex)
            + margins.top + rangeHeight) + ')');

        const rangeGroup =  chartGroup
          .select('.rangeGroup')
          .selectAll('.rangeGroup' + myClass)
          .data((d: any) => d.data)
          .join((group: any) => {
            const enter = group.append('g').attr('class', 'rangeGroup' + myClass);
            enter.append('text').attr('class', 'rangeLabel');
            if(myChart.props.navArrow){
            enter.append('g').attr('class', 'rangeArrow');}
            enter.append('g').attr('class', 'rangeBarGroup');
            enter.append('rect').attr('class', 'rangeMouseoverRect');
            return enter;
          });

        rangeGroup.select(".rangeMouseoverRect")
          .attr("fill", "transparent")
          .attr("width", (d: any) => xScale(d3.max(d.data, (m: any) => m[1])))
          .attr("height", rangeHeight+10)
          .attr('x', margins.range + 24)
          .attr('y', (d: any, i: any) => (i * rangeHeight-4))
          .on("mousemove", (event: any, d: any) => {
            const tooltipData: any = [];
            d.data.forEach((t: any) => {
              tooltipData.push({
                name: t.data.groupVar,
                range: d.range,
                key: t.key,
                value: t.data[t.key],
                fill:myChart.props.barFill[t.key]
              })
            })
            myChart.showTooltip('groupHBar', tooltipData, event.offsetX, event.offsetY, width, height);
          })
          .on('mouseout',  () => {
            myChart.hideTooltip('groupHBar');
          });

          rangeGroup.select(".rangeArrow").selectAll('svg').remove()
          rangeGroup.select(".rangeArrow")
          .append('svg')
              .attr('width', 9)
              .attr('height', 9)
              .attr('x',  70)  // Adjust X position
              .attr("cursor", "pointer")
              .attr('y', (d: any, i: any) => (i * rangeHeight) + (rangeHeight / 2) - 4)  // Adjust Y to vertically center the arrow
              .append('path')
              .attr('d', 'M4.92271 3.16802L5.44121e-08 3.16802L1.9988e-07 4.83198L4.92271 4.83198L3.00993 6.81824L4.14797 8L8 4L4.14797 3.36755e-07L3.00992 1.18176L4.92271 3.16802Z')
              .attr('fill', '#1363DF')
              .on("click", (event: any, d: any) => {
                    const tooltipData: any = [];
                    d.data.forEach((t: any) => {
                      tooltipData.push({
                        group: t.data.groupVar,
                        range: d.range,
                        key: t.key,
                        value: t.data[t.key],
                        fill:myChart.props.barFill[t.key]
                      })
                    })
                    myChart.NavigationArrow('groupHBar', tooltipData, event.offsetX, event.offsetY, width);
                  }); 

        rangeGroup.select(".rangeLabel")
          .attr("font-family", "Poppins")
          .attr("font-size", 12)
          .attr("font-weight", 700)
          .attr("fill", "#101D42")
          .attr("cursor", myChart.props.navArrow?"pointer":'default')
          .attr('text-anchor', 'start')
          .attr('x', -10)
          .attr('y', (d: any, i: any) => (i * rangeHeight) + (rangeHeight/2) + 5)
          // .text((d: any) => d.range)
          .text(getGroupLabel)
          
          rangeGroup.select(".rangeLabel").on("click", (event: any, d: any) => {
            if(myChart.props.navArrow){
            const tooltipData: any = [];
            d.data.forEach((t: any) => {
              tooltipData.push({
                group: t.data.groupVar,
                range: d.range,
                key: t.key,
                value: t.data[t.key],
                fill:myChart.props.barFill[t.key]
              })
            })
            myChart.NavigationArrow('groupHBar', tooltipData, event.offsetX, event.offsetY, width);
          }
          })  
          .on("mouseover", (event:any, d: any) => {
            if(myChart.props.navArrow){
            d3.select(event.currentTarget).attr("fill", '#1363DF');}
            if(getGroupLabel(d).includes("..")){
              myChart.showTooltip('groupFullName', d.range.toUpperCase(), event.offsetX, event.offsetY, width, height);
            }
          })
          .on("mouseout", (event:any) => {
            if(myChart.props.navArrow){
            d3.select(event.currentTarget).attr("fill", "#101D42");}
            myChart.hideTooltip("groupFullName");
          });

        rangeGroup.select(".rangeBarGroup")
          .attr("transform", (d: any, i: any) => "translate(" + (margins.range + 24) + ","
          + (i * (rangeHeight)) + ")")

        const rangeBarGroup =  rangeGroup.select(".rangeBarGroup")
          .selectAll('.rangeBarGroup' + myClass)
          .data((d: any) => {
            d.data.map((m: any, i: any) => {
              const dataWidth = xScale(m[1]) - xScale(m[0]);
              m.width = dataWidth < 2 && dataWidth !== 0 ? 2 : dataWidth;
              if(dataWidth < 2 && i < (d.data.length - 1)){
                d.data.filter((f: any, index: any) => index > i).forEach((m: any) => {
                  m.extraX = dataWidth === 0 ? 0 : 2 - dataWidth;
                })
              }
            });
            d.data.map((m:any, i: any) => m.borderRadius = i === (d.data.length - 1) ? 2 : 0)
            return d.data
          })
          .join((group: any) => {
            const enter = group.append('g').attr('class', 'rangeBarGroup' + myClass);
            enter.append('path').attr('class', 'rangeBar');
            return enter;
          });


        rangeBarGroup.select(".rangeBar")
          .attr("d", (d: any) =>  "M0,0 L"
            + (d.width - d.borderRadius) +
            ",0 Q"+ d.width
            + ",0  " + d.width + "," + d.borderRadius + " L " + d.width
            + "," + (rangeHeight - d.borderRadius+5) + " Q " + d.width + "," + + (rangeHeight+5) + " "
            + (d.width - d.borderRadius) +
            "," +  (rangeHeight+5) +" L 0," +  (rangeHeight+5 ) + " Z")
          .attr("transform", (d: any) => "translate(" + (xScale(d[0]) + (d.extraX === undefined ? 0 : d.extraX)) +
          ",-4)")
          .attr("fill", (d: any) => myChart.props.barFill[d.key])
      }

      function drawLegend(legendData: any){

        const legendGroup = mySvg
          .selectAll('.legendGroup' + myClass)
          .data(legendData)
          .join((group: any) => {
            const enter = group.append('g').attr('class', 'legendGroup' + myClass);
            enter.append('circle').attr('class', 'legendCircle');
            enter.append('text').attr('class', 'legendLabel');
            return enter;
          });

        legendGroup.attr("transform", "translate(" + (margins.left + margins.brush + margins.mid + 36) + ",15)");

        legendGroup.select('.legendCircle')
          .attr('id', (d: any) => 'circle_legendItem' +(d).replaceAll(' ','_'))
          .attr('fill', (d: any) => myChart.props.barFill[d])
          .attr('r', 4);

        legendGroup.select('.legendLabel')
          .attr('id', (d: any) => 'legendItem' + (d).replaceAll(' ','_'))
          .attr('y', 5)
          .attr('fill', '#101D42')
          .attr('font-weight', '500')
          .attr('font-size', '12')
          .attr('font-family', 'Poppins')
          .text((d: any) => myChart.props.barLabels[d].toUpperCase())

        let legendX = 0;
        mySvg.selectAll('.legendLabel').each(function(d: any): any {
          //@ts-ignore
          const myObject = this;
          const myWidth: any = document.getElementById((myObject.id).replaceAll(' ','_'))?.getBoundingClientRect().width;
          d3.select(myObject).attr('x', legendX + 12);
          mySvg.select('#circle_' + (myObject.id).replaceAll(' ','_')).attr('cx', legendX);
          legendX += (40 + myWidth)
        });



      }

      function getGroupLabel(d: any){
        let myLabel = d.range//.toUpperCase();
        while(!checkWidth(myLabel) || myLabel === ".."){
          if(myLabel.includes("..")){
            myLabel = myLabel.substr(0, myLabel.length-2);
          }
          myLabel = myLabel.substr(0, myLabel.length - 1) + "..";
        }
        return myLabel;
        function checkWidth(myText:any){
          if((measureWidth(myText,(myChart.props.navArrow ?10:8)) ) > margins.range){
            return false
          } else {
            return true
          }
        }

      }
      function measureWidth(myText: any, myFontSize: any): any {
        const context: any = document.createElement('canvas').getContext('2d');
        context.font = myFontSize + 'px sans-serif';
        return context.measureText(myText).width;
      }
      function brushed(event: any): void {

        const selection = event.selection;
        if (selection !== null) {
          // myChart.hideTooltip('groupHBar');
          // myChart.hideTooltip("groupFullName");
          const yDomain: any = selection.map(yScaleAll.invert);
          
          if((yDomain[1] - yDomain[0]) < 0.99) {
            if((yDomain[0] + 1) > groupSet.length){
              yDomain[0] = yDomain[1] - 1;
            } else {
              yDomain[1] = yDomain[0] + 1;
            }
            mySvg.select('#brushGroup' + myClass)
              //@ts-ignore
              .call(brush.move, myChart.props.showBrush === false ? null : [yScaleAll(yDomain[0]), yScaleAll(yDomain[1])]);
          } else {
            visibleGroups = ((yDomain[1] - yDomain[0])/groupSet.length) * groupSet.length;
            yScale.domain(yDomain);

            drawChart();

            const handleSouthY = +mySvg.select('#brushGroup' + myClass).select('.handle--s').attr('y');
            const handleNorthY = +mySvg.select('#brushGroup' + myClass).select('.handle--n').attr('y');

            mySvg.select('.handleLeftLine1' + myClass)
              .attr('y1', handleSouthY + 3)
              .attr('y2', handleSouthY + 3);

            mySvg.select('.handleLeftLine2' + myClass)
              .attr('y1', handleSouthY + 7)
              .attr('y2', handleSouthY + 7);

            mySvg.select('.handleRightLine1' + myClass)
              .attr('y1', handleNorthY + 3)
              .attr('y2', handleNorthY + 3);

            mySvg.select('.handleRightLine2' + myClass)
              .attr('y1', handleNorthY + 7)
              .attr('y2', handleNorthY + 7);

            mySvg.select('#brushGroup' + myClass)
              .selectAll('.handle')
              .attr('fill', 'white')
              .attr('rx', 4)
              .attr('ry', 4)
              .attr('x', (margins.brush - 24) / 2)
              .attr('width', 24)
              .style('filter',  'url(#drop-shadow)');
          }
          }
      }
        this.stop(10).then(() => this.isLoading = false);
          // so now
      // get horizontal brush working with just groups
      // then add bars and range labels?
  }

  // property & data for the chart
  getWordCloudData(){

    let cgf:any = this.item.config

    this.data.forEach((obj: any) => {
      obj.error_type_nm = "defect_count";
      if (this.item.config?.stacked_bar?.value !== 'true') { obj.error_type_code = "D";  }
    });

    const barFill:any = {};
    const barLabels:any = {};
    
    // Populate the objects dynamically
    cgf?.bar_Fill?.values?.forEach((item:any) => {
        barFill[item.col_value] = item.color;  // Assign the color to barFill
        barLabels[item.col_value] = item.col_value;  // Assign the col_value as the label
    });

    this.HbarChartData = this.data;
    this.props = {
      chartHeight: cgf?.y_axis_nav?.value === 'true' ? 475 : 500, // Minimum is 300px, depends on the number of groups
      rangeVar: 'error_type_nm',
      stackVar: cgf?.stacked_var?.['api_resp_column_nm'] || 'error_type_code', // Value
      groupVar: cgf?.y_axis?.['api_resp_column_nm'] || 'field_name', // Y-axis
      valueVar: cgf?.x_axis?.['api_resp_column_nm'] || 'defect_count', // X-axis
      xAxisLabel:  cgf?.x_axis?.["api_resp_column_display_nm"].toUpperCase(),
      yAxisLabel:  cgf?.y_axis?.["api_resp_column_display_nm"].toUpperCase(),
      barFill:barFill || { 'D': '#9024E7' },
      barLabels:barLabels || { 'D': 'defects' },
      XtickCount: cgf?.y_axis_nav?.value === 'true' ? 11 : 6,
      navArrow: cgf?.y_axis_nav?.value === 'true'
    };
    
    // if(cgf?.static_data?.value=='true'){
    // this.props.barFill=  { 'MERGED':'#1363DF' ,'UNMERGED':'#8C23E6' , 'NET NEW':'#2ED67B', }
    // this.props.barLabels= { 'MERGED':'MERGED' ,'UNMERGED':'UNMERGED' , 'NET NEW':'NET NEW',}
    // } 
      // @ts-ignore
      if (this.HbarChartData.length > 0){
        this.noData =false
        setTimeout(() => {
          this.plotChart();
        }, 50);
      }else{
        this.isLoading = false
        this.noData = true
      }
  }
}
