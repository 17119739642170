<div class="widget-container" #heatMapNEW>

  <main>
    <article #fs fullscreen-able [class.is-active]="isFullscreen" style="background-color: white;">
      <div class="header">
        <div>
          <icon-chart 
            [headerConfig]="item.config.widget_header_config"
            [rowDataLength]="data"
            [data]="data"
            [chartName]="'DataGrid'" 
            [pageKey]="pageKey"
            [config]="heatMapNEW" 
            [item]="item" 
            [expand]="fs" 
            [fullscreen]="isFullscreen"
            (expendCol)="toggleAll($event)"
            (screenChanges)="toggleFullscreen($event)"></icon-chart>
        </div>

        <app-progress-indefinite *ngIf="isLoading "></app-progress-indefinite>

        <div style="display: flex;align-items: center;position: relative;justify-content: center;"
          *ngIf="!isLoading && data?.length"><duc-legend [config]="config.legend"></duc-legend></div>

        <div *ngIf="!isLoading && !data?.length" class="no-data">
          <span class="bg-text">There is no data for this time period</span>
        </div>

        <div *ngIf="!isLoading && data?.length" [ngClass]="this.item.config['tool_tip_type'].value"
          [ngStyle]="{'height': isFullscreen ? (window.innerHeight - 130) + 'px' : 'auto','overflow':isFullscreen ?'auto':'none','margin-bottom':'15px'}"
          style="overflow-y: auto;"
          >

          <!-- [ngStyle]="{'height': isFullscreen ? window.innerHeight + 'px' : 'auto','overflow':'auto'}" -->

          <div class="tree-table">
            <duc-tree [config]="config.tree"></duc-tree>
            <duc-status-overview [config]="config.statusOverview" [data]="data" (cellSelected)="toggleModal($event)">
              <!-- TODO use ngComponentOutlet in Angular 16+-->
              <app-status-tooltip-dem #tooltip *ngIf="this.item.config['tool_tip_type'].value === 'dem'"
                [config]="config.tooltip"></app-status-tooltip-dem>
              <app-status-tooltip-dpm #tooltip *ngIf="this.item.config['tool_tip_type'].value === 'dpm'"
                [config]="config.tooltip"></app-status-tooltip-dpm>
            </duc-status-overview>
          </div>

        </div>
      </div>

    </article>
  </main>
</div>